/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
import React, {
  useEffect, useRef, useState, forwardRef, useImperativeHandle, useContext
} from 'react';
import router, { useRouter } from 'next/router';

import { useQuery } from '@tanstack/react-query';

import Image from '@atoms/Image';
import Loader from '@atoms/Atom/Loader';

import QuickQuestionItem from '@molecules/QuickQuestionItem';
import useOutsideClickDetect from '@lib/hooks/useOutsideClickDetect';
import { getCorrectedFilter, processDeletedLinks } from '@lib/link-library-helper';

import { StreamDataContext } from '@components/context/StreamContext';

import { getAuth } from '@services/identity.service';
import { deleteLinkLibrary } from '@services/link-library.service';
import { updateEngageType, getLinkLibraryPaginated } from '@services/youtube-platform.service';

import ShareModel from './ShareModel';
import { ActionType } from './LinkLibraryReducer';
import LinkLibraryDropdown from './LinkLibraryDropdown';

import useNotificationTimer from '@lib/hooks/useNotificationTimer ';
import NextImage from 'next/image';

const LinkLibrary = forwardRef(({
  streamName,
  streamId,
  isResult,
  showUserName,
  linksData,
  platformType,
  pushCmdToFS,
  currentRosFeature,
  colorData,
  setShowNotification,
  isSandbox = false,
  isScreenshot,
  livestreamData,
}, ref) => {
  const joinType = useRouter().query?.joinType;
  const [refetch, setRefetch] = useState(true);
  const [linkData, setLinkData] = useState([]);
  const [atBottom, setAtBottom] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [showShareModel, setShowShareModel] = useState(false);
  const [currentFilter, setCurrentFilter] = useState(currentRosFeature?.setting?.LinkType || 'All');

  const auth = getAuth();

  const scrollContainerRef = useRef(null);

  useOutsideClickDetect(showDropdown, 'LinkTypeDropDown', setShowDropdown);
  useNotificationTimer(currentRosFeature, linkData, setShowNotification);

  const stopCurrentFeature = () => {
    router.push({ pathname: `/streams/${streamId}`, query: joinType && { joinType } });
  };

  useImperativeHandle(ref, () => ({ stopCurrentFeature }));

  const {
    linkLibraryState: { links, filters, allLinks, groupedLinks },
    dispatchLinkLibrary: dispatch
  } = useContext(StreamDataContext);

  const setLinks = (newLinks) => {
    dispatch({
      type: ActionType.SET_LINKS,
      links: newLinks,
    });
  };

  const setAllLinks = (newLinks) => {
    dispatch({
      type: ActionType.SET_ALL_LINKS,
      links: newLinks,
    });
  };

  const groupDeletedLinks = (links) => {
    dispatch({
      type: ActionType.UPDATE_GROUPED_DELETED_LINKS,
      links
    });
  };

  const handleSetLinks = (newLinks) => {
    setLinks(newLinks);
    if (newLinks.length === 10 &&
      currentFilter.toLowerCase() === 'all') {
      setAllLinks([...allLinks, ...newLinks]);
    }
    setLinkData([...allLinks, ...newLinks]);
  };

  const handleScroll = () => {
    const { scrollTop, clientHeight, scrollHeight } = scrollContainerRef.current;
    const isAtBottom = scrollTop + clientHeight >= scrollHeight - 20;

    setAtBottom(isAtBottom);
    setRefetch(isAtBottom);
  };

  const handleDelete = async (index, syncToFs = false) => {
    if (index === undefined || index === null) return;
    const link = linkData[index];
    dispatch({
      type: ActionType.DELETE_LINK,
      link
    });
    const response = await deleteLinkLibrary(link.id);
    groupDeletedLinks(link);

    if (!response.status && !response.data?.entity) {
      setLinks([...links].splice(index, 0, link));
      dispatch({
        type: ActionType.ADD_LINK,
        link,
        index
      });
    } else if (syncToFs) {
      pushCmdToFS('deleteLinkLibraryLink', { link });
    }
  };

  const handleSelectedFilter = (newFilters, syncToFS = false) => {
    setShowDropdown(false);
    setCurrentFilter(getCorrectedFilter(newFilters.linkType));

    if (syncToFS) {
      dispatch({
        type: ActionType.UPDATE_FILTERS,
        filters: newFilters
      });
      if (pushCmdToFS) { pushCmdToFS('setLinkLibraryFilters', { filters: newFilters }); }
    }
  };

  const { data, isError, isLoading, error, isFetching } = useQuery(
    {
      queryKey: ['paginated_links', currentFilter],
      queryFn: () => getLinkLibraryPaginated(streamId, {
        skip: filters.linkType.toLowerCase()
          === 'all' ? allLinks.length || 0 : 0,
        filter: currentFilter
      }, auth),
      enabled: !isScreenshot,
      keepPreviousData: false,
      refetchInterval: refetch || atBottom ? 3000 : false,
    },

  );

  useEffect(() => {
    dispatch({
      type: 'UPDATE_FILTERS',
      filters: {
        linkType: currentRosFeature?.setting?.LinkType || 'All'
      }
    });
  }, [currentRosFeature]);

  useEffect(() => {
    if (data?.entity?.rows) {
      const filteredLinks = processDeletedLinks(data?.entity?.rows, groupedLinks, deleteLinkLibrary);
      handleSetLinks(filteredLinks);
    }
    if (data?.entity?.count === 0) {
      dispatch({ type: ActionType.RESET_STATES });
    }
  }, [data]);

  useEffect(() => {
    if (linksData?.length > 0) return;
    updateEngageType(streamId, 'linkLibrary', { platformType });
    const scrollContainer = scrollContainerRef.current;
    scrollContainer?.addEventListener('scroll', handleScroll);
    return () => {
      scrollContainer.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    const filter = getCorrectedFilter(filters.linkType);
    setCurrentFilter(prev =>
      prev !== filter ? filter : prev);
    if (filter.toLowerCase() !== 'all') setLinkData(links);
    const { scrollHeight, clientHeight, scrollTop } = scrollContainerRef.current;
    if (scrollHeight <= clientHeight + scrollTop) setRefetch(true);
    else setRefetch(false);
  }, [links, allLinks, filters]);

  useEffect(() => {
    if (isResult && linksData?.length > 0) {
      setLinkData(linksData);
      return;
    }
    if (linkData.length > 0 && !isResult) {
      setTimeout(() => {
        setShowNotification(false);
      }, 500);
    }
  }, [currentRosFeature, linkData]);

  const bgImg = livestreamData?.settings?.linkLibraryImageSrc;
  const bgImgOpacity = livestreamData?.settings?.linkLibraryImageOpacity / 100;
  let bgColor;
  let textColor;
  if (isResult) {
    bgColor = livestreamData?.colors?.linkLibraryBgColor || '#111111';
    textColor = livestreamData?.colors?.linkLibraryTextColor || '#ffffff';
  }

  return (
    <div
      style={{
        backgroundColor: bgColor,
        color: textColor,
        height: 'calc(100vh - 218px)',
        backgroundImage: isResult && bgImg ? `url(${process.env.NEXT_PUBLIC_BUCKET_URL}${bgImg})` : 'none',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        zIndex: 1
      }}
      className="h-full relative" >
      {showShareModel && <ShareModel
        selectedFilter={filters.linkType}
        setShowShareModel={setShowShareModel}
        streamId={streamId}
        isResult={isResult}
        streamName={streamName} />}
      <div className='pt-2 px-1 z-10 relative'>
        <LinkLibraryDropdown
          filters={filters}
          showDropdown={showDropdown}
          setShowDropdown={setShowDropdown}
          setShowShareModel={setShowShareModel}
          handleSelectedFilter={handleSelectedFilter}
          showShareButton={!isSandbox}
        />
      </div>
      <div
        ref={scrollContainerRef}
        id={`${isResult ? '' : 'scrollableDiv11'}`}
        style={isSandbox ? {
          height: 'calc(100vh - 450px)',
        } : {}}
        className={`z-2 h-full hide-scrollbar scrollable-container w-full overflow-auto rounded-lg  ${isResult && linksData ? 'pb-0' : 'pb-18'}`}
      >

        {isLoading ? <Loader showMessages={false} />
          : (<>  {linkData.length !== 0 ? linkData.map((link, index) => (
            <>
              {!groupedLinks[link.body] &&
                <QuickQuestionItem key={index} id={link.id} index={index} question={link}
                  isResult={isResult} interactionType={'linkLibrary'} isLoading={isLoading}
                  showUserName={showUserName} selectedFilter={filters.linkType}
                  currentRosFeature={currentRosFeature}
                  handleDelete={handleDelete} />
              }
            </>
          ))
            : <div className="absolute m-0 top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2">
              <div className='flex flex-col items-center'>
                <Image src={`${filters.linkType !== 'Email Id' ? '/images/link-chat.svg' : '/images/email-chat.svg'}`} />
                <div className='mt-8'>
                  <div className='flex justify-center items-center'>
                    <span className='font-medium	text-white text-center text-lg leading-6'>No one has added</span><br />
                  </div>
                  <div className='flex justify-center items-center'>
                    <span className='font-medium	text-white text-center text-lg leading-6'>a {filters.linkType === 'Email Id' ? 'Email Id' : 'link'} yet in the chat</span>
                  </div>
                </div>
              </div>
            </div>
          }
          </>)}
        <div className='h-10 absolute bottom-0 left-1/2 transform -translate-x-1/2  '>
          {isFetching && !isLoading &&
            < Loader showMessages={false} />
          }
        </div>
      </div >
    </div >
  );
});

export default LinkLibrary;
